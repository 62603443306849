﻿/// <reference path="../_Variables.less" />
.subscribe-form{
    .person-form {
        border-top : 1px solid @color-brand-primary-3;
        padding-top: 20px;
        padding-bottom: 10px;
        input, select,textarea{
            background-color:#F1F1F1;
            border: 1px solid #BFBFBF;
            margin-bottom:20px;
            line-height:1.8;
            padding-left:5px;
        }

        .input-small{
            min-width:110px;
            width:110px;
        }

        .input-extra-small{
            min-width:70px;
            width:70px;
        }
        &:first-child{
            .remove-person {
                display:none;
            }
        }
    }
    .remove-person{
        margin-left:27.5%;
        margin-bottom:10px;
    }
    .form-buttons{
        margin-top:30px;
        margin-left:26.5%;
    }
    .form-buttons-wrapper{
        border-top : 1px solid @color-brand-primary-3;
        margin-top:15px;
    }
}
