﻿@import (reference) "_Variables.less";

.fontSize(@sizeValue) {
    @remValue: @sizeValue / 16px * 1rem;
    @pxValue: (@sizeValue);
    font-size: ~"@{pxValue}px";
    font-size: ~"@{remValue}";
}

.text-shadow (@string: 0 1px 3px rgba(0, 0, 0, 0.25)) {
    text-shadow: @string;
}

.box-shadow (@string) {
    -webkit-box-shadow: @string;
    -moz-box-shadow: @string;
    box-shadow: @string;
}

.drop-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
    -webkit-box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
    box-shadow: @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.inner-shadow (@x: 0, @y: 1px, @blur: 2px, @spread: 0, @alpha: 0.25) {
    -webkit-box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
    box-shadow: inset @x @y @blur @spread rgba(0, 0, 0, @alpha);
}

.border-radius (@radius: 5px) {
    border-radius: @radius;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.border-radiuses (@topright: 0, @bottomright: 0, @bottomleft: 0, @topleft: 0) {
    border-radius: @topleft @topright @bottomright @bottomleft;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
}

.transition (@transition) {
    -webkit-transition: @transition;
    -moz-transition: @transition;
    -ms-transition: @transition;
    -o-transition: @transition;
    transition: @transition;
}

.transform(@string) {
    -webkit-transform: @string;
    -moz-transform: @string;
    -ms-transform: @string;
    -o-transform: @string;
    transform: @string;
}

.scale (@factor, @factorY : @factor) {
    -webkit-transform: scale(@factor @factorY);
    -moz-transform: scale(@factor @factorY);
    -ms-transform: scale(@factor @factorY);
    -o-transform: scale(@factor @factorY);
    transform: scale(@factor @factorY);
}

.rotate (@deg) {
    -webkit-transform: rotate(@deg);
    -moz-transform: rotate(@deg);
    -ms-transform: rotate(@deg);
    -o-transform: rotate(@deg);
    transform: rotate(@deg);
}


.rotateX (@deg) {
    -webkit-transform: rotateX(@deg);
    -moz-transform: rotateX(@deg);
    -ms-transform: rotateX(@deg);
    -o-transform: rotateX(@deg);
    transform: rotateX(@deg);
}


.rotateY (@deg) {
    -webkit-transform: rotateY(@deg);
    -moz-transform: rotateY(@deg);
    -ms-transform: rotateY(@deg);
    -o-transform: rotateY(@deg);
    transform: rotateY(@deg);
}


.rotateZ (@deg) {
    -webkit-transform: rotateZ(@deg);
    -moz-transform: rotateZ(@deg);
    -ms-transform: rotateZ(@deg);
    -o-transform: rotateZ(@deg);
    transform: rotateZ(@deg);
}



.skew (@deg, @deg2) {
    -webkit-transform: skew(@deg, @deg2);
    -moz-transform: skew(@deg, @deg2);
    -ms-transform: skew(@deg, @deg2);
    -o-transform: skew(@deg, @deg2);
    transform: skew(@deg, @deg2);
}

.translate (@x, @y: 0) {
    -webkit-transform: translate(@x, @y);
    -moz-transform: translate(@x, @y);
    -ms-transform: translate(@x, @y);
    -o-transform: translate(@x, @y);
    transform: translate(@x, @y);
}

.translate3d (@x, @y: 0, @z: 0) {
    -webkit-transform: translate3d(@x, @y, @z);
    -moz-transform: translate3d(@x, @y, @z);
    -ms-transform: translate3d(@x, @y, @z);
    -o-transform: translate3d(@x, @y, @z);
    transform: translate3d(@x, @y, @z);
}

.perspective (@value: 1000) {
    -webkit-perspective: @value;
    -moz-perspective: @value;
    -ms-perspective: @value;
    perspective: @value;
}

.transform-origin (@x: center, @y: center) {
    -webkit-transform-origin: @x @y;
    -moz-transform-origin: @x @y;
    -ms-transform-origin: @x @y;
    -o-transform-origin: @x @y;
    transform-origin: @x @y;
}

.transition-delay(@time) {
    -moz-transition-delay: @time;
    -o-transition-delay: @time;
    -webkit-transition-delay: @time;
    transition-delay: @time;
}

.fillSvg(@color) {
    path, polygon {
        fill: @color;
    }
}
