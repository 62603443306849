﻿/// <reference path="../_Variables.less" />
html {
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

body {
	background-color: #e9eadf;
}

.header-container {
	background-color: @color-white;
}

.content-wrapper {
	background-color: #fff;
}

.content-photo-right-first, .content-photo-left-first {
	margin-top: 10px;
}

@media screen and (max-width: @small_breakpoint-max) {
	.content-photo-left, .row .content-photo-left-first {
		margin-right: 0;
	}

	.content-photo-right, .row .content-photo-right-first {
		margin-left: 0;
	}

	.row .content-photo-left-first, .row .content-photo-right-first, .content-photo-left, .content-photo-right {
		padding-left:0;
		padding-right:0;
	}
}

.content-photo-left, .content-photo-right {
	img {
		margin-bottom: 20px;
		margin-top: 20px;
	}
}

.content-photo-left, .content-photo-left-first {
	margin-right: 20px;
	padding-left: 0;
}

.content-photo-right, .content-photo-right-first {
	margin-left: 20px;
}


*, *:before, *:after {
	box-sizing: inherit;
}

.img-responsive, .img-responsive img {
	.img-responsive();
}

.container-maxed {
	max-width: 1278px;
}

.hidden-by-default {
	display: none;
}

.content-wrapper {
	max-width: 2000px;
	margin: 0 auto;
}

/*@media screen and (min-width: 2000px){
	.content-wrapper{
		background-color: @color-white;
	}
	.header-container{
		
	}
}*/
