﻿/// <reference path="../_Variables.less" />

@media (min-width: @screen-sm-min) {
	.pull-right-sm {
		float: right;
	}

	.clear-left-sm {
		clear: left;
	}

	.pull-left-sm {
		float: left;
	}

	.clear-right-sm {
		clear: right;
	}
	.content-blocks-home a div div{
		max-height:240px;
	}
}

.content-blocks {
	&.content-blocks-home {
		//border-top: 1px solid @color-background-level-1;
		a{
			div{
				margin-top:5px;
				/*height: 600px;*/
				div{
					/*position: relative;*/
					/*width: auto;*/
					/*overflow: hidden;*/
					/*line-height:240px;*/
					img{
						left:0;
						max-height:257px;
						/*position: absolute;
						left: 50%;
						top: 50%;
						height: 100%;
						width: auto;
						-webkit-transform: translate(-50%,-50%);
						-ms-transform: translate(-50%,-50%);
						transform: translate(-50%,-50%);*/
						/*margin: 0 auto;*/
						/*max-height: 240px;*/
						/*width:auto;
						height:240px;
						overflow:hidden;*/
						/*top: 50%;*/
						/*transform: translateY(-50%);*/
						/*vertical-align:middle;*/
						/*bottom:auto;*/
						/*bottom:0;
						left:0;*/
						/*position:absolute;*/
						/*vertical-align: 0;*/
						/*min-height: 240px;*/
						/*height:240px;*/
					}
				}
			}
		}
	}
}

.project-banner, .page-banner{
    text-decoration:none;
    &:hover{
        text-decoration:none;
    }
}