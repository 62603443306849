﻿/// <reference path="../_Variables.less" />
@max-carousel-photo-width: @max-content-width / 3 * 2;
@max-carousel-photo-height: @max-carousel-photo-width / 3 * 2;

.max-photo-size-container {
	max-width: @max-carousel-photo-width;
	

	.carousel-inner {
		& > .item {
			max-width: @max-carousel-photo-width;
			margin: 0 auto;
		}

		.img-responsive {
			max-height: @max-carousel-photo-height;
			margin: 0 auto;
		}

		background-color: @color-brand-primary-1;
	}

	.carousel-indicators {
		.img-responsive {
			max-height: 100px;
			-webkit-transition: opacity 0.6s ease-in-out;
			-moz-transition: opacity 0.6s ease-in-out;
			-ms-transition: opacity 0.6s ease-in-out;
			-o-transition: opacity 0.6s ease-in-out;
			transition: opacity 0.6s ease-in-out;
		}
	}
}
