﻿/// <reference path="../_Variables.less" />

.icon {
    display: inline-block;
    vertical-align: middle;
    -ms-behavior: url("/content/polyfills/backgroundsize.min.htc");
    background-size: contain;

    svg {
        max-height: 100%;
        max-width: 100%;
        vertical-align: middle;
        float: left;
    }
}

.inline-text-icon {
    width:1em;
    height:1em;
    margin-top:-0.05em;
    margin-right:0.4em;
}
