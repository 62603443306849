﻿/// <reference path="../_Variables.less" />
.content-nav-link {
	margin-top:5px;
	a{
		margin-top: 0.5em;
		color:@color-link;
		&:hover,
		&:focus{
			color:@color-link-hover;
			.icon svg,
			path{
				fill: @color-brand-primary-2;
			}
		}
	}
}

.pagination-container{
    margin-top:15px;
}

.pagination {
    > li {

        > a, > span {
            min-width: 44px;
            line-height:44px;
            vertical-align:middle;
            padding-top:0;
            padding-bottom:0;

        }
    }
}
