﻿/// <reference path="../_Variables.less" />

body {
    font-family: @base_font-family;
    font-size: @base_font-size;
    letter-spacing: 0.2px;
    color: @color-brand-secondary-1;
}

p, ul, dl, .paragraph {
    font-size: 1.125em; /* 20px */
    line-height: 1.5000em; /* 30px */
    margin: 0;
}


.cms {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding-left: 1em;
            /*text-indent: -.7em;*/
        }

        li:before {
            content: "• ";
            color: @color-list-first-level;
        }
		
		@media (max-width: @small_breakpoint-max){
			li a {
				font-size: 0.95em;
			}
		}
    }
}

.paragraph {
    max-width: 35em;
}

.paragraph-small {
    font-size: 1.125rem; /* 18px */
    line-height: 1.5em; /* 30px */
    /*margin-top: 1.6667em;*/
    /*margin-bottom: 0.0000em;*/

	p, ul, dl, .paragraph, a {
		font-size: 1.125rem; /* 18px */
	}
}

h1, .heading-1 {
    font-size: 1.625em; /* 26px */
    line-height: 1.3333em; /* 40px */
    margin-top: 1.3333em;
    margin-bottom: 0.0000em;
}

h2, .heading-2 {
    font-size: 1.375em; /* 24px */
    line-height: 1.3333em; /* 32px */
    margin-top: 1.3333em;
    margin-bottom: 0.0000em;
	font-weight: normal;
}

h3, .heading-3 {
    font-size: 1.25em; /* 20px */
    line-height: 1.5000em; /* 30px */
    margin-top: 1.5000em;
    margin-bottom: 0.0000em;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

h1 {
    color: @color-heading-h1;
}

h2, h3, h4, h5, h6 {
    color: @color-heading-h2;
}

.default-page-title {
    margin-bottom: -0.8em;
}

@padding-size: 0.4em;

.cms ul {
    overflow: hidden;
    padding-left: @padding-size;

    li {
        position: relative;
        padding-left: @padding-size;

        &::before {
            position: absolute;
            left: 0;
            top: 0;
            margin-left: -(@padding-size / 2);
        }
    }
}

.cms li ul {
    padding-left: @padding-size;

    li::before {
        color: @color-list-second-level;
        font-size: 75%;
        line-height: 2.25em;
        //content: "  - ";
    }
}


@media screen and (min-width:@medium_breakpoint-min) and (max-width:@medium_breakpoint-max) {
    body {
        font-size: @medium_font-size;
    }

    p, ul, dl, .paragraph {
        font-size: 1.125em; /* 18px */
        line-height: 1.5em; /* 30px */
        /*margin: 0 0 1.6667em 0;*/
    }

    h1, .heading-1 {
        font-size: 1.75em; /* 30px */
        line-height: 1.3333em; /* 40px */
        margin-top: 1.3333em;
        margin-bottom: 0.0000em;
    }

    h2, .heading-2 {
        font-size: 1.5em; /* 24px */
        line-height: 1.2500em; /* 30px */
        margin-top: 1.2500em;
        margin-bottom: 0.0000em;
		font-weight: normal;
    }

    h3, .heading-3 {
        font-size: 1.375em; /* 20px */
        line-height: 1.5000em; /* 30px */
        margin-top: 1.5000em;
        margin-bottom: 0.0000em;
    }

    .paragraph-small {
        font-size: 1em; /* 16px */
        line-height: 1.8750em; /* 30px */
        /*margin-top: 1.8750em;*/
        /*margin-bottom: 0.0000em;*/

		p, ul, dl, .paragraph, a {
			font-size: 1rem; /* 18px */
		}
    }
}


@media screen and (min-width:@large_breakpoint-min) {
    body {
        font-size: @large_font-size;
    }

    p, ul, dl, .paragraph {
        font-size: 1em; // 1em; /* 16px */
        line-height: 1.5em; /* 26px */
        /*margin: 0 0 1.6250em 0;*/
    }

    .col-phone {
        a {
            letter-spacing: 0;
        }
    }

    h1, .heading-1 {
        font-size: 1.875em; /* 30px */
        line-height: 1.5em; /* 52px */
        margin-top: 0.8667em;
        margin-bottom: 0.0000em;
    }

    h2, .heading-2 {
        font-size: 1.625em; /* 20px */
        line-height: 1.3000em; /* 26px */
        margin-top: 1.3000em;
        margin-bottom: 0.0000em;
		font-weight: normal;
        // font-size: 1.5000em; /* 24px */
        //  line-height: 1.0833em; /* 26px */
        //  margin-top: 1.0833em;
        // margin-bottom: 0.0000em;
    }

    h3, .heading-3 {
        font-size: 1.5em; /* 20px */
        line-height: 1.2500em; /* 26px */
        margin-top: 1.2500em;
        margin-bottom: 0.0000em;
    }


    .paragraph-small {
        font-size: 0.875em; /* 14px */
        line-height: 1.7em; /* 30px */
        /*margin-top: 2.1429em;*/
        margin-bottom: 0.0000em;
		color: @color-footer-text;

		p, ul, dl, .paragraph, a {
			font-size: 0.875rem; /* 18px */
		}
    }
}

.cms {
    a {
        font-weight: normal;
    }
}

a {
    color: @color-link;
    text-decoration: underline;
    //font-weight: bold;
    &:hover, &:focus {
		color: @color-link-hover;
        text-decoration: underline;
    }

    &.block-link {
        color: inherit;
        font-weight: inherit !important;
        display: block;

        .link {
            color: @color-link;
            font-weight: bold;

            &:hover, &:focus {
                text-decoration: underline;
            }
        }

        &:hover, &:focus {
            h2, h3, h4, h5, h6, .hover {
                color: @color-link-hover;
            }

            .link {
                color: lighten(@color-brand-primary-1,20%);
            }
        }
    }
}

ul.custom {
    list-style: none;
    padding-left:2em;
    & > li {
        clear: left;

        &:before {
            content: "";
            height: 1em;
            width: 1em;
            display: block;
            float: left;
            margin-left: -1.25em;
            margin-top:0.25em;
            background-position: center;
            background-repeat: no-repeat;
            background-size: 100%;
        }
    }

    &.checklist {
        > li:before {
            background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20encoding%3D%22utf-8%22%3F%3E%0D%0A%3C%21--%20Generator%3A%20Adobe%20Illustrator%2016.0.0%2C%20SVG%20Export%20Plug-In%20.%20SVG%20Version%3A%206.00%20Build%200%29%20%20--%3E%0D%0A%3C%21DOCTYPE%20svg%20PUBLIC%20%22-%2F%2FW3C%2F%2FDTD%20SVG%201.1%2F%2FEN%22%20%22http%3A%2F%2Fwww.w3.org%2FGraphics%2FSVG%2F1.1%2FDTD%2Fsvg11.dtd%22%3E%0D%0A%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0D%0A%09%20width%3D%2215px%22%20height%3D%2215px%22%20viewBox%3D%220%200%2015%2015%22%20enable-background%3D%22new%200%200%2015%2015%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cg%3E%0D%0A%09%3Cpath%20fill%3D%22%238CC63F%22%20d%3D%22M14.622%2C0L15%2C0.569c-1.542%2C1.223-3.259%2C3.106-5.147%2C5.65c-1.889%2C2.544-3.331%2C4.921-4.326%2C7.13l-0.8%2C0.57%0D%0A%09%09C4.063%2C14.404%2C3.612%2C14.765%2C3.374%2C15c-0.094-0.357-0.299-0.942-0.616-1.754l-0.303-0.74c-0.433-1.063-0.834-1.85-1.206-2.358%0D%0A%09%09C0.878%2C9.64%2C0.461%2C9.302%2C0%2C9.135C0.779%2C8.27%2C1.492%2C7.836%2C2.141%2C7.836c0.555%2C0%2C1.172%2C0.793%2C1.85%2C2.38l0.335%2C0.798%0D%0A%09%09c1.219-2.164%2C2.783-4.268%2C4.693-6.31C10.93%2C2.661%2C12.797%2C1.093%2C14.622%2C0z%22%2F%3E%0D%0A%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E");// url(';utf8,<svg xmlns="http://www.w3.org/2000/svg" width="100%" height=" 100%"  viewBox=" 0 0 15 15"  preserveAspectRatio=" xMidYMid meet" ><g><path fill="#8CC63F" d="M14.622,0L15,0.569c-1.542,1.223-3.259,3.106-5.147,5.65c-1.889,2.544-3.331,4.921-4.326,7.13l-0.8,0.57	C4.063,14.404,3.612,14.765,3.374,15c-0.094-0.357-0.299-0.942-0.616-1.754l-0.303-0.74c-0.433-1.063-0.834-1.85-1.206-2.358		C0.878,9.64,0.461,9.302,0,9.135C0.779,8.27,1.492,7.836,2.141,7.836c0.555,0,1.172,0.793,1.85,2.38l0.335,0.798		c1.219-2.164,2.783-4.268,4.693-6.31C10.93,2.661,12.797,1.093,14.622,0z"/></g></svg>');
            background-size: 100%;
            background-position: center;
        }
    }
}
